@font-face {
	font-family: 'Roboto-ThinItalic';
	src: url('../fonts/robotothinitalic.eot');
	src: url('../fonts/robotothinitalic.eot?#iefix') format('embedded-opentype'),url('../fonts/robotothinitalic.svg#robotothinitalic') format('svg'),url('../fonts/robotothinitalic.ttf') format('truetype'),url('../fonts/robotothinitalic.woff') format('woff'),url('../fonts/robotothinitalic.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-Thin';
	src: url('../fonts/robotothin.eot');
	src: url('../fonts/robotothin.eot?#iefix') format('embedded-opentype'),url('../fonts/robotothin.svg#robotothin') format('svg'),url('../fonts/robotothin.ttf') format('truetype'),url('../fonts/robotothin.woff') format('woff'),url('../fonts/robotothin.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-Regular';
	src: url('../fonts/robotoregular.eot');
	src: url('../fonts/robotoregular.eot?#iefix') format('embedded-opentype'),url('../fonts/robotoregular.svg#robotoregular') format('svg'),url('../fonts/robotoregular.ttf') format('truetype'),url('../fonts/robotoregular.woff') format('woff'),url('../fonts/robotoregular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-MediumItalic';
	src: url('../fonts/robotomediumitalic.eot');
	src: url('../fonts/robotomediumitalic.eot?#iefix') format('embedded-opentype'),url('../fonts/robotomediumitalic.svg#robotomediumitalic') format('svg'),url('../fonts/robotomediumitalic.ttf') format('truetype'),url('../fonts/robotomediumitalic.woff') format('woff'),url('../fonts/robotomediumitalic.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-Medium';
	src: url('../fonts/robotomedium.eot');
	src: url('../fonts/robotomedium.eot?#iefix') format('embedded-opentype'),url('../fonts/robotomedium.svg#robotomedium') format('svg'),url('../fonts/robotomedium.ttf') format('truetype'),url('../fonts/robotomedium.woff') format('woff'),url('../fonts/robotomedium.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-LightItalic';
	src: url('../fonts/robotolightitalic.eot');
	src: url('../fonts/robotolightitalic.eot?#iefix') format('embedded-opentype'),url('../fonts/robotolightitalic.svg#robotolightitalic') format('svg'),url('../fonts/robotolightitalic.ttf') format('truetype'),url('../fonts/robotolightitalic.woff') format('woff'),url('../fonts/robotolightitalic.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-Light';
	src: url('../fonts/robotolight.eot');
	src: url('../fonts/robotolight.eot?#iefix') format('embedded-opentype'),url('../fonts/robotolight.svg#robotolight') format('svg'),url('../fonts/robotolight.ttf') format('truetype'),url('../fonts/robotolight.woff') format('woff'),url('../fonts/robotolight.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-Italic';
	src: url('../fonts/robotoitalic.eot');
	src: url('../fonts/robotoitalic.eot?#iefix') format('embedded-opentype'),url('../fonts/robotoitalic.svg#robotoitalic') format('svg'),url('../fonts/robotoitalic.ttf') format('truetype'),url('../fonts/robotoitalic.woff') format('woff'),url('../fonts/robotoitalic.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-BoldItalic';
	src: url('../fonts/robotobolditalic.eot');
	src: url('../fonts/robotobolditalic.eot?#iefix') format('embedded-opentype'),url('../fonts/robotobolditalic.svg#robotobolditalic') format('svg'),url('../fonts/robotobolditalic.ttf') format('truetype'),url('../fonts/robotobolditalic.woff') format('woff'),url('../fonts/robotobolditalic.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-Bold';
	src: url('../fonts/robotobold.eot');
	src: url('../fonts/robotobold.eot?#iefix') format('embedded-opentype'),url('../fonts/robotobold.svg#robotobold') format('svg'),url('../fonts/robotobold.ttf') format('truetype'),url('../fonts/robotobold.woff') format('woff'),url('../fonts/robotobold.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-BlackItalic';
	src: url('../fonts/robotoblackitalic.eot');
	src: url('../fonts/robotoblackitalic.eot?#iefix') format('embedded-opentype'),url('../fonts/robotoblackitalic.svg#robotoblackitalic') format('svg'),url('../fonts/robotoblackitalic.ttf') format('truetype'),url('../fonts/robotoblackitalic.woff') format('woff'),url('../fonts/robotoblackitalic.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-Black';
	src: url('../fonts/robotoblack.eot');
	src: url('../fonts/robotoblack.eot?#iefix') format('embedded-opentype'),url('../fonts/robotoblack.svg#robotoblack') format('svg'),url('../fonts/robotoblack.ttf') format('truetype'),url('../fonts/robotoblack.woff') format('woff'),url('../fonts/robotoblack.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
