.row {
	max-width: 1250px;
	margin: 0 auto;
}

.plr {
	padding-left: 50px;
	padding-right: 50px;
	@media (min-width: $screen-md) {
		padding-left: 40px;
		padding-right: 40px;
	}
}

main{
	section{
		h2{

		}
		p{

		}
	}
}

.inner{
	width: 100%;
	max-width: 800px;
	margin: auto;
}

.btn{
    background: #2f2f2f;
    color: #fff;
    margin: 20px auto;
    border-radius: 40px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    border: 0;
    width: 150px;
    display: block;
    border:0;
        font-size: 14px;
    text-align: center;
    text-decoration: none;
}