.simplecta{
	text-align:center;
	display: block;

	@media (max-width: $screen-xs) {
		text-align:left;
	}

	.row{
		border-bottom: 1px dashed #c9c9ca;
		padding: 50px 0;
		background: white;

		@media (max-width: $screen-sm) {
			padding: 50px 30px;
		}
	
	}
	h2{
		color:$darkgrey;
		margin-bottom:20px;
	}
	img{
		max-width:100%;
		height:auto;
	}
}