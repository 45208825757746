.shareblock{

	.row{
		background: $darkgrey;
		padding: 50px 0;

		@media (max-width: $screen-sm) {
			padding: 50px 30px;
		}
		@media (max-width: $screen-xs) {
			padding: 30px;
		}
	}

	&__inner{
		display: block;
		width:420px;
		max-width:100%;
		margin:auto;
		text-align:center;

		@media (max-width: $screen-sm) {
			text-align:left;
		}

		h2{
			margin-bottom:10px;
			color:white;
		}
		p{
			margin-bottom:40px;
			color:white;

			@media (max-width: $screen-xs) {
				margin-bottom:20px;
			}
		}
	}

	&__links{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		@media (max-width: $screen-sm) {
			justify-content: left;
		}
		
		a{
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 10px;
			height:60px;
			width:60px;
			background: #6b94d1;
			border-radius: 50%;
			text-align:center;
			line-height:60px;

			@media (max-width: $screen-sm) {
				height:40px;
				width:40px;
			}

			&:first-of-type{
				@media (max-width: $screen-sm) {
					margin-left:0;
				}
			}

			img{
				height:15px;
				width:auto;
			}

			&.email{
				
			}
			&.fb{
				img{
					height: 20px;
				}
				
			}
			&.twt{

			}
			&.ggl{

			}
		}
	}
}