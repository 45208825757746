.modal_content{
	display: block;
	width:100%;
	max-width:700px;
	margin:auto;
	position: relative;
	padding:50px;
	background: white;

	&--centered{
		text-align:Center;
	}

	h4{
		font-size:20px;
		color:black;
		margin-bottom:30px;
		text-align:center;
	}

	.message{
		font-size: 13px;
		color: #576772;
		text-align:center;
		    padding: 0 .5rem 1rem;

		    .share_box{
		    	padding: 0;
		    	border: 0;
		    	margin: 0;
		    }

		    .frm_message{
		    	color:green;

		    	p{
		    		color:green;
		    	}
		    }

	}
	hr{
		background: #f1f1f1;
		height: 1px;
		width: 100%;
		display: block;
		margin-bottom: 30px;
		border: 0;
	}
}

.modal_form{
	.frm_forms {
		width:100%;
		display: block;
	}
	.frm_error{
		color:red;
		font-size:10px;
	}
		hr{
			background: #f1f1f1;
			height: 1px;
			width: 100%;
			display: block;
			margin-bottom: 30px;
			border: 0;
		}

		.form_item{
			display: block;
			width:50%;
			float:left;

			@media only screen and (max-width: $screen-sm){
		        width:100%;
		    }

			&--full{
				width:100%;
				clear:both;
			}

			&__age{
				width:40%;
				padding-right:5%;
				display:inline-block;
			}
			&__years{
				width:58%;
				display:inline-block;
			}
		}

		input[type=text], input[type=email]{
			background: #f3f4f7;
			height:40px;
			line-height:40px;
			padding: 0 20px;
			border:0;
			display: block;
			width:100%;
			font-family: 'Montserrat', sans-serif;
			font-size:9px;
			color:#a3a4a5;
			text-transform:uppercase;
		}

		textarea{
			background: #f3f4f7;
			height:200px;
			padding: 20px;
			border:0;
			display: block;
			width:100%;
			font-family: 'Montserrat', sans-serif;
			font-size:9px;
			color:#a3a4a5;
			text-transform:uppercase;
		}

		

		div.selector{
			width:100% !important;
			height:40px;
			padding:0 !important;

			span{
				display:block;
				width:100%  !important;
				height:40px;
				line-height:38px;
				font-size:9px;
				color:#a3a4a5;
				text-transform:uppercase;
				letter-spacing:1px;
				font-family: 'Montserrat', sans-serif;
				padding: 0 20px;
				text-align:left;
				background: #f3f4f7 url(../images/select_arrow.png) no-repeat right 10px center;

			}
			select{
				height:40px;
				cursor:pointer;
				top:0;
			}
		}

		div.radio{
			margin-right:10px;

			span{
				border-radius:50%;
				border: 1px solid #edeef4;
				background: white;

				&.checked{
					background: #edeef4;
				}
			}


			input{
				display: block;
				cursor:pointer;
			}
		}

		.form_item--linkedin{
			input{
				padding-left:50px;
				background:  #f3f4f7 url(../images/icons/LinkedIn.png) no-repeat 10px center;
			}
		}

		.form_item--notify{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			font-size:13px;
			color:#576772;
		}

		.form_item--center{
			text-align:center;
		}

		//uploader styles start
		div.uploader{
			background: #f3f4f7;
			height:40px;
			width:100%;
			font-family: 'Montserrat', sans-serif;
			font-size:9px;
			color:#a3a4a5;
			text-transform:uppercase;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			span.filename{
				width:30px;
				background: url(../images/icons/upload.png) no-repeat left center;
				text-indent:-999px;
			}
			span.action{
			}
		}

		.form_item--cv{
			div.uploader{
				span.action{
					width: 100px;
					visibility: hidden;
					position: relative;
					overflow:visible;

					&:before{
						visibility: visible;
						content:'Upload cv in';
						display: block;
						position: absolute;
						top:0;
						left:0;
						text-transform:uppercase;
						width:80px;
						font-weight:600;
					}
					&:after{
						content: 'PDF format';
						display: block;
						text-transform: uppercase;
						color: black;
						position: absolute;
						top: 0;
						left: 80px;
						visibility: visible;
						width: 65px;
						font-weight:600;
					}
				}
			}
		}
		.form_item--coverletter{
			div.uploader{
				span.action{
					width: 150px;
					visibility: hidden;
					position: relative;
					overflow:visible;

					&:before{
						visibility: visible;
						content:'Upload cover letter';
						display: block;
						position: absolute;
						top:0;
						left:0;
						text-transform:uppercase;
						width:123px;
						font-weight:600;
					}
					&:after{
						content: 'optional';
						display: block;
						position: absolute;
						top: 0;
						left: 123px;
						visibility: visible;
						width: 60px;
						font-weight:600;
						font-size:7px;
					}
				}
			}
		}
		//uploader styles end

		input[type=submit]{
			font-family: 'Montserrat', sans-serif;
			font-size: 9px;
			text-transform: uppercase;
			font-weight: 600;
		}

		.frm_dropzone.dz-clickable.frm_single_upload{
			width:100%;
			margin:0;
			padding:0;
			max-width:100%;
			    height: 40px;
    min-height: 40px;
		}
		.frm_dropzone.dz-clickable{
			border:0;
			background: #f3f4f7;
			height:40px;
			width:100%;
			font-family: 'Montserrat', sans-serif;
			font-size:9px;
			color:#a3a4a5;
			text-transform:uppercase;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
		.frm_dropzone.dz-clickable.frm_single_upload .dz-message{
			margin:0;
		}
		.frm_icon_font.frm_upload_icon{
			width: 30px;
			background: url(../images/icons/upload.png) no-repeat left center;
			text-indent: -999px;
			text-overflow: ellipsis;
			display: block;
			overflow: hidden;
			white-space: nowrap;
			float: left;
			cursor: default;
			height: 24px;
			margin: 2px 0 2px 2px;
			line-height: 24px;
			width: 30px;
			padding: 0 10px;
		}
		.frm_dropzone .frm_small_text{
			font-size:10px;
			display:none;
		}
		.frm_upload_text{
			height: 28px;
			line-height: 28px;
			font-family: 'Montserrat', sans-serif;
			font-size: 9px;
			color: #a3a4a5;
			text-transform: uppercase;
			display: block;
			    width: 167px;

			b{
				color:black;
			}
    }

    #frm_field_72_container{
    	.frm_upload_text{
    		width:200px;
    	}
    }

	.frm_dropzone .dz-preview.dz-file-preview .dz-image{
		display:none;
	}
	.frm_dropzone .dz-preview .dz-details .dz-size{
		display:inline-block;
		    margin: 0;
		   font-family: 'Montserrat', sans-serif;
    font-size: 9px;
    color: #a3a4a5;
    text-transform: uppercase;
    background: none;
}
.frm_dropzone .dz-preview .dz-remove{
	position:absolute;
	right:10px;
	top:50%;
	text-indent:-9999px;
	overflow:hidden;
	width:30px;
	height:30px;
	margin-top:-15px;
	background: url(../images/close_black.png) no-repeat center center / 30%;
	cursor: pointer;
	display: block;
	z-index: 9999;
}
	.frm_dropzone {
		.dz-progress, 
		.dz-error-message, 
		.dz-success-mark,
		.dz-size{
			display:none;
		}
	}
	.frm_dropzone .dz-preview.dz-file-preview .dz-details{
		padding:0;
		position: relative;
		top: inherit;
		left: inherit;
		height: 40px;
		line-height: 40px;
	}
	.frm_dropzone .dz-preview.dz-file-preview{
	    min-height: 40px;
	    width: 100%;
    margin: 0;
	}
	.frm_dropzone .dz-preview .dz-details .dz-filename{
		display:inline-block;
		font-family: 'Montserrat', sans-serif;
    font-size: 9px;
    color: #a3a4a5;
    text-transform: uppercase;
    overflow:visible;
     background: none;
	}
	.frm_dropzone .dz-preview .dz-details .dz-filename span, 
	.frm_dropzone .dz-preview .dz-details .dz-size span{
		background: none;

		&:hover{
			background: none;
		}
	}
	.frm_dropzone .dz-preview .dz-details .dz-filename:not(:hover){
		overflow:visible;
	}

	.frm_message{
		text-align:center;
		color: #a3a4a5;
	}
    

}

.modals_container{
	display:none;
}

