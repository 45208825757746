.blog_single{
	.row{
		display: block;
		background: white;
		padding: 30px;
		color:$darkgrey;

		h1{
			color:$darkred;
			margin-bottom:20px;

			 @media (min-width: 650px) {
		    	font-size: 30px;
		    }
		}

		p{
			color:$darkgrey;
			margin-bottom:20px;

			&:first-of-type{
				font-size: 20px;
				line-height: 24px;
				font-weight:bold;
			}
		}
		blockquote{
			margin:0 auto 20px auto;
			font-size:24px;
			line-height:28px;
			font-weight:bold;
			color:#5b8edd;
			display: block;

			p{
				color:#5b8edd;
			}
		}
	}

	&__img{
		display: block;

		img{
			max-width:100%;
			width:100%;
			height:auto;

			&.small{
				width: calc(50% - 7.5px);

				&.margin-right{
					margin-right:15px;
				}
			}
		}

		a{
			max-width:100%;
			width:100%;
			height:auto;
			margin-bottom:15px;	
			display: block;
			float:left;

			&:nth-of-type(2){
				margin-right:15px;
			}

			&.small{
				width: calc(50% - 7.5px);
			}
		}

		@media (max-width: $screen-md) {
			width:50%;
		}
		@media (max-width: 600px) {
			width:100%;
		}
	}
	&__share{
		display: block;
		height:30px;
		margin-bottom:15px;	

		a{
			height:30px;
			width:30px;
			margin-right:10px;
			border:1px solid #bfbfbf;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			float:left;

			svg{
				height:15px;
				width:auto;
			}

			&.fb{
				svg{
					height:20px;
				}
			}
		}


	}

	.bottom{
		width:100%;
		display: block;

		@media (max-width: $screen-md) {
			width:50%;
			padding-left:30px;
		}
		@media (max-width: 600px) {
			width:100%;
			padding-left:0;
		}
	}

	aside{
		display: block;
		width:325px;
		max-width:100%;
		margin-bottom:20px;
		margin-right:30px;
		float:left;
		padding-top:5px;

		footer{
			display: block;
			padding: 15px 15px 15px 50px;
			border-top:1px solid #bfbfbf;
			border-bottom:1px solid #bfbfbf;
			font-size:13px;
			background: url(../images/assets/pencil.png) no-repeat left center;
		}

		@media (max-width: $screen-md) {
			float:none;
			width:100%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
		@media (max-width: 600px) {
			flex-direction: column;
		}
	}

	&--event{
		aside{
			table{
				td{
					padding:0 20px 10px 0;
				}
			}
		}

		.row h1{
			margin-bottom: 30px;

		    @media (min-width: 650px) {
		    	font-size: 40px;
		    }
		}

		.event-top{
			display: block;
			padding: 0 0 20px 0;

			img{
				width:70px;
				height:auto;
			}

			span{
				display: inline-block;
				float:right;
				border: 1px solid $darkgrey;
				color:$darkred;
				padding: 10px 30px;
				    margin-top: 16px;
			}
		}

		.blogcontent{
			padding-top:16px;
		}
	}
}

.blogcontent{
	display: block;
	overflow:hidden;

	ul {
		margin: 0 0 40px 20px;

		li {
			list-style: disc outside none;
			margin-bottom:10px;
		}
	}


	ol {
		margin: 0 0 40px 20px;

		li{
			list-style: decimal outside;
			margin-bottom:10px;
		}

	}

	h2{
		color:$darkgrey;
		font-weight:normal;
		margin-bottom:10px;
		font-size:26px;
	}

	h3{
		font-size:22px;
		line-height:26px;
		font-weight:normal;
		color:$darkgrey;
		margin-bottom:10px;
		margin-bottom:10px;
	}
	p{
		color:$darkgrey;
		margin-bottom:20px;
	}

	a{
		color:$darkred;
		text-decoration:none;

		&.download{
			display: block;
			padding: 10px 0 10px 50px;
			background: url(../images/assets/download.png) no-repeat left center / auto 40px;
		}
	}

	img{
		max-width:100%;
		height:auto;

		&.alignleft{
			float:left;
			margin: 0 20px 20px 0;
		}
		&.aligncenter{
			display: block;
			margin: 0 auto 20px auto;
		}
	}


	.responsive-video{
		display: block;
		margin: 0 20px 20px 0;
	}

	@media (max-width: $screen-sm) {
		.responsive-video{
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			padding-top: 25px;
			height: 0;
			display: block;
			float:none;
			margin: 0 0 20px 0;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

}