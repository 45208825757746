.header {
	width:100%;
	position: absolute;
	top:0;
	left:0;
	z-index:9;
	
	.row{
		height:325px;
		background: url(../images/header_bg.png) no-repeat center top / 100%;
		padding: 20px;
		position: relative;

		@media (max-width: $screen-sm) {
		    background: url(../images/header_bg.png) no-repeat center top/800px;
		    padding: 10px;
		}
		@media (max-width: $screen-xs) {
			background: url(../images/header_bg_mobi.png) no-repeat center top / 100%;
		}
	}

	h1{
		display: inline-block;

		a{
			display: block;

			img{
				width:230px;
				height:auto;
				display: block;

				@media (max-width: $screen-md) {
					width:160px;
				}
				@media (max-width: $screen-xs) {
					width:120px;
				}
			}
		}
	}

	nav{
		display: block;
		max-height:0;
		height:auto;
		overflow:hidden;
		position: absolute;
		top:0;
		right:0;
		width:100%;
		transition: max-height 0.3s;
		background: white url(../images/assets/just_red_table.svg) no-repeat 30px 30px / 50px;
		text-align:center;
		-webkit-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.19);
		-moz-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.19);
		box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.19);

		@media (min-width: $screen-md) {
			width:500px;
			background: #2f2f2f;
		}

		ul{
			padding:100px 50px 50px 50px;
		}

		li{
			display: block;
			width:100%;

			a{
				display: block;
				width:100%;
				padding: 20px;
				color:#5b8edc;
				font-size:22px;
				text-decoration:none;
				border-bottom:1px dashed #c9c9ca;

				@media (min-width: $screen-md) {
					color:white;
					text-align: left;
				}
			}

		}

		button{
			display: block;
			top:30px;
			right:30px;
			width:25px;
			height:25px;
			background: url(../images/assets/close_white.svg) no-repeat center center / 100%;
			position: absolute;
			border:0;
			cursor:pointer;

			@media (max-width: $screen-md) {
				background: url(../images/assets/close.svg) no-repeat center center / 100%;
			}
		}
	}

	.main-nav-btn{
		display: block;
		color: $darkred;
		width:50px;
		position: absolute;
		top:50px;
		right:50px;
		border:0;
		background: transparent;
		cursor:pointer;

		img{
			width:100%;
			height:auto;
		}
		span{
			display: block;
			margin-bottom:5px;
		}

		@media (max-width: $screen-md) {
			top:30px;
			right:30px;
			//width:40px;

			span{
				display: none;
			}
		}
		@media (max-width: $screen-sm) {
			top:20px;
			right:10px;
		}
	}

	&.open{
		nav{
			max-height:1000px;
		}
	}

	&--single{
		position: relative;
		top:inherit;
		left:inherit;

		.row{
			height:auto;
			background: white;
			border-bottom:1px dashed #afafaf;
		}
	}
}

.breadcrumb{
	display: block;
	margin-bottom:20px;
	color:$darkred;

	a{
		color:$darkred;
		text-decoration:none;
	}
	span{
		color:$darkgrey;
	}
}