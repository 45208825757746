.events{
	.row{
		display: block;
		background: white;
		padding: 0;

		h1{
			color:$darkred;
			font-size: 30px;
			padding: 40px 0 20px 0;

			@media (max-width: 650px) {
				padding: 30px;
			}
		}

		.blogintro{
			p {
			    color: #2f2f2f;
			    margin-bottom: 20px;

			    &:first-of-type {
				    font-size: 22px;
				    line-height: 26px;
				    font-weight: bold;
				}
			}

			/* Override bootstrap ul ol styling */
			ul {
			  margin: 0 0 20px 20px;

			  li {
			      list-style: disc outside none;
			    }
			}


			ol {
			margin: 0 0 20px 20px;

			    li{
			        list-style: decimal outside;
			    }

			}
		}

	} 

	article{
		display: block;
		border-bottom:1px dashed #adadad;
		color:$darkgrey;
		width:100%;

		&:hover{
			background: #fef2e4;
		}

		.isfeatured{

		}

		.event-content{
			display: block;
			position: relative;
			text-decoration:none;

			&__content{
				display: block;
				overflow:hidden;

				.btn{
					margin:20px 0;
				}
			}

			@media (min-width: 650px) {
				//padding-left:320px;
				min-height:230px;
			}
		}

		&.featured{
			background: url('../images/assets/paper.jpg') no-repeat center center / cover;
			// @media (max-width: 650px) {
			// 	background: url('../images/assets/paper.jpg') no-repeat center center / cover;
			// }

			// .event-top{
			// 	padding:0;

			// 	span{
			// 		margin-top: 0;
			// 	}
			// }
		}

		h2{
			color:$darkgrey;

			@media (max-width: $screen-md) {
				font-size: 22px;
				font-weight: normal;
			}
		}
		p{
			color:$darkgrey;
			margin-bottom:20px;
		}
		img{
			margin-bottom:20px;
			width:100%;

			@media (min-width: 650px) {
				&.featimg{
					width:300px;
					//position: absolute;
					//left:0;
					display: block;
					//top:0;
					float:left;
					margin-right:30px;
				}
			}
		}
		.arrow{
			display: block;
			width:70px;
			margin:0 auto 30px auto;

			img{
				width:100%;
				height:auto;

				@media (max-width: 650px) {
					margin-bottom:0;
				}
			}
		}

		.event-top{
			display: block;
			padding: 20px 0;

			img{
				width:70px;
				height:auto;
				margin-left:-10px;
				margin-bottom:0;

				@media (min-width: 650px) {
					float:left;
				}
			}

			span{
				display: inline-block;
				float:right;
				border: 1px solid $darkgrey;
				color:$darkred;
				padding: 10px 30px;
				margin-top: 16px;

				@media (min-width: 650px) {
					float:left;
				}
			}
		}

		// @media (max-width: $screen-md) {
		// 	width:calc(50% - 30px);
		// }
		@media (max-width: 650px) {
			width:100%;
			margin:0;
			padding:30px;
		}
	}

	.articles{
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		// @media (max-width: 650px) {
		// 	width:calc(100% + 60px);
		// 	margin-left:-30px;
		// }	
	}

	&--issue{
		article{
			padding: 30px 30px 10px 30px;

			.event-top{
				padding:0;

				img{
					width: 100px;
				}
			}
			.arrow{
				    margin: 0 auto 0 auto;
			}

			.inner{
				@media (min-width: 650px) {
					display: flex;
	    			flex-direction: row;
	    			position: relative;

	    			h2{
						display: flex;
				        align-items: center;
				        //justify-content: center;
	    			}
	    			.arrow{
	    				display: flex;
				        align-items: center;
				        margin: 0 0 0 auto;
	    			}

	    		}
			}
		} 
	}


	&--accordian{
		article{
			padding: 30px;
			width:100%;
			margin:0;
			position: relative;

			.event-top{
				padding:0;

				img{
					width: 100px;
				}

				@media (min-width: 650px) {
					margin-right:50px;
				}
			}
			.arrow{
				    margin: 0 auto 0 auto;
			}
			.content{
				max-height:0;
				overflow:hidden;

				/* Override bootstrap ul ol styling */
				ul {
				  margin: 0 0 20px 20px;

				  li {
				      list-style: disc outside none;
				    }
				}


				ol {
				margin: 0 0 20px 20px;

				    li{
				        list-style: decimal outside;
				    }

				}
			}
			.openbtn,
			.closebtn{
				display: inline-block;
				position: absolute;
				top:30px;
				right:0;

				img{
					width:60px;
					height:auto;
					margin:0;
				}

				@media (min-width: 650px) {
					top:20px;
				}
			}
			.closebtn{
				display:none
			}

			&.open{
				.content{
					max-height:1000px;
					padding: 20px 0 0 0;

					@media (min-width: 650px) {
						padding: 20px 0 0 140px;
					}
				}
				.closebtn{
					display: block;
				}
				.openbtn{
					display: none;
				}
			}

			.inner{
				@media (min-width: 650px) {
					.topsection{
						display: flex;
		    			flex-direction: row;
		    			position: relative;

		    			h2{
							display: flex;
					        align-items: center;
					        //justify-content: center;
					        padding-right:60px;
					        font-size:25px;
		    			}
					}
					
	    		}
			}
		}  
	}


	&--blog{
		article{
			padding: 60px 0 30px;

			.event-top span{
				color:$darkgrey;
				margin-bottom:20px;
			}


			&.featured{
				.event-top{
					padding:0;

					span{
						margin-top: 0;
					}
				}
			}

			@media (max-width: 650px) {
				padding: 30px 30px 0 30px;
			}
		}
	}

	&--resources{
		article{
			padding: 60px 0 30px;

			.event-top span{
				color:$darkgrey;
				margin-bottom:20px;
			}

			@media (max-width: 650px) {
				padding: 30px 30px 0 30px;
			}

				.responsive-video{
					margin-bottom:20px;
					width:100%;

					iframe {
						width: 100%;
						height:250px;
					}


					@media (min-width: 650px) {
						width:300px;
						height:230px;
						position: absolute;
						top:0;
						left:0;

						iframe {
							width: 100%;
							height:230px;
						}
					}
				}

		}
	}

}

.intro{
	display: block;
	color:$darkgrey;

	.row{
		background: #f2e8dc;
		padding: 50px;

		h1{
			color:$darkred;
			margin-bottom:20px;

			@media (min-width: 650px) {
				font-size:30px;
			}
		}
		p{
			color:$darkgrey;
			margin-bottom:20px;

			&:first-of-type{
				font-size: 22px;
				line-height: 26px;
				font-weight: bold;

				@media (max-width: $screen-sm) {
					font-size: 18px;
					line-height: 22px;
				}
			}
		}

		@media (max-width: $screen-sm) {
			padding: 30px;
		}
	}

	&__share{
		display: block;
		height:30px;
		margin-bottom:15px;	

		a{
			height:30px;
			width:30px;
			margin-right:10px;
			border:1px solid #bfbfbf;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			float:left;

			svg{
				height:15px;
				width:auto;
			}

			&.fb{
				svg{
					height:20px;
				}
			}
		}

		span{
			height: 30px;
			line-height: 30px;

			@media (max-width: $screen-xs) {
				font-size:13px;
			}
		}


	}	

	&--white{
		.row{
			background: white;
		}
	}

	&--nopbottom{
		.row{
			padding-bottom:0;
		}

		.intro__share{
			margin:0;
		}
	}

	&--childstandardaccordian{
		.row{
			border-bottom: 1px dashed #adadad;
			padding: 50px;
		}
		
	}

	&--issue{

	}


	&--contact{
		.row{
			padding-bottom:20px;
		}
	}

	&--imgtext{
		.row{
			padding-top:0;
			}
		}

}

.noarticles{
	color:$darkgrey;
	p{
		color:$darkgrey;
	}
}



.imgtextitem{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-bottom:1px solid #2f2f2f;
	padding: 30px 0;

	&__img{
		width:120px;
		display: block;
		margin-right:30px;

		@media (max-width: $screen-sm) {
			margin:auto;
		}

		img{
			max-width:100%;
			height:auto;
		}
	}
	&__content{
		width: calc(100% - 150px);
		 display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;

        @media (max-width: $screen-sm) {
        	width:100%;
        }

        h3{
        	color:#2f2f2f;
        	margin-bottom:10px;
        }

        p{
        	font-size: 16px;
        	line-height: 22px;
        	font-family: "faricy-new-web", sans-serif;

        	&:first-of-type{
        		font-size: 16px !important;
        		line-height: 22px !important;
        		font-weight:normal !important;
        	}
        }
	}
}