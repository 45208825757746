.hero{
	.row{
		height:700px;
		display: block;
		position: relative;

		@media (max-width: $screen-md) {
			height:570px;
		}
	}

	&__content{
		display: block;
		width: 600px;
		max-width:40%;
		padding: 40px;
		position: absolute;
		bottom:50px;
		left:0;
		color:white;
		background: $darkred;

		h2{
			font-size:30px;
			margin-bottom:10px;

				@media (max-width: $screen-lg) {
					font-size:25px;
				}
				@media (max-width: $screen-md) {
					font-size:22px;
				}
		}
		p{
			font-size: 20px;

			@media (max-width: $screen-lg) {
				font-size:18px;
			}
			@media (max-width: $screen-md) {
				font-size:16px;
			}
		}

		@media (max-width: $screen-md) {
			max-width:50%;
		}
		@media (max-width: $screen-sm) {
			max-width: 100%;
			width:100%;
			bottom:0;
			min-height:250px;
		}
	}

	&__bg{
		display: block;
		position: absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;

		@media (max-width: $screen-sm) {
			height: calc(100% - 250px);
		}
	}
}