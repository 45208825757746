.joinform{
	.row{
		
	}

	&__inner{
		padding: 100px 50px 50px 50px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		background: $darkred url(../images/form_bg.jpg) no-repeat top right / cover;
		position: relative;

		@media (max-width: $screen-sm) {
			background: #d73e28 url(../images/form_bg.jpg) no-repeat top center/cover;
			padding: 250px 30px 30px 30px;
		}
		@media (max-width: $screen-xs) {
			background: #d73e28 url(../images/form_bg_mobi.jpg) no-repeat top center/cover;
			padding: 200px 30px 30px 30px;
		}
	}

	h3{
		color:white;
		margin-bottom:20px;
		font-size:30px;

		@media (max-width: $screen-sm) {
			font-size:24px;
		}
	}

	p{
		margin-bottom:30px;
	}

	.form_main{
		display: block;
		width:50%;

		h3{

		}

		@media (max-width: $screen-sm) {
			width:100%;
		}
	}

	.frm_forms{

	}

	.join_logo{
		display: block;
		width:50%;
		position: relative;


		img{
			width:220px;
			max-width:80%;
			display: block;
			position: absolute;
			bottom:0;
			right:0;

			@media (max-width: $screen-xs) {
				margin-left:10px;
			}
		}

		@media (max-width: $screen-sm) {
			width:100%;
			position: absolute;
			top:0;
			left:0;
			height:220px;

			@media (max-width: $screen-xs) {
				height:220px;
			}

			img{
				right:inherit;
				left:0;
				width:190px;
			}
		}
	}

	form{
		.frm_message {
			margin-bottom: 17px;
		}

		.frm_error {
			color: #fff;
			font-family: 'Roboto-Bold';
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 17px;
		}

		fieldset {
			border: 0;
			margin: 0;
			padding: 0;

		}

		.frm_primary_label{
			font-weight:bold;
			margin-bottom:20px;
			display: block;
		}


		input {
			background: #fff;
			border: 0;
			color:  $darkgrey;
			font-family: 'Roboto-Regular';
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 25px;
			padding: 0 30px;
			width: 100%;
			height:40px;
			line-height:40px;

			@media (min-width: $screen-md) {
				margin-bottom: 17px;
			}

		}

		input[type="submit"] {
			background: $darkgrey;
			color: #fff;
			margin-bottom: 7px;
			margin-top: 1px;
			padding-left: 50px;
			padding-right: 62px;
			width: auto;
			border-radius:40px;
			height:40px;
			line-height:40px;
			cursor:pointer;

			@media (min-width: $screen-md) {
				margin-top: 3px;
				padding-left: 35px;
				padding-right: 40px;
			}
		}

		.frm_radio,
		.frm_checkbox{
			margin-bottom: 17px;
		}

		div.radio,
		div.checker {
			margin-right:10px;
			
			span{
				border: 1px solid white;

				&.checked{
					background: white;
				}
			}	
		}
	}
}