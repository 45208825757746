.vidblock{
	text-align:center;

	.row{
		background: #3cbfb6;
		padding: 80px 0;
		position: relative;

		@media (max-width: $screen-md) {
			padding: 80px 100px;
		}
		@media (max-width: $screen-sm) {
			padding: 50px 30px;
		}
	}

	&__inner{
		width:750px;
		max-width:100%;
		display: block;
		margin:auto;
		text-align:center;

		h2{
			margin-bottom:10px;
			color:white;
		}
		p{
			margin-bottom:40px;
			color:white;
		}

		@media (max-width: $screen-xs) {
			text-align:left;
		}

	}


	&__vid{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom:40px;
		min-height:250px;

		> div{
			width:50%;
			display: block;
		}

		@media (max-width: $screen-sm) {
			flex-direction: column;
			min-height:auto;

			> div{
				width:100%;
			}
		}


		a{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height:100%;
			width:100%;


			img{
				
			}
		}

		&__quote{
			background: white;	
			padding: 40px;
			color: #3cbfb6;
			font-size:22px;
			font-weight:medium;
			text-align:left;
			font-family: $sans_medium;

			p{
				color: #3cbfb6;
				font-family: $sans_medium;
				font-size:22px;
				font-weight: 500;
				line-height:1.3;
			}

			@media (max-width: $screen-sm) {
				padding: 30px 30px 0 30px;
			}
		}
		&__video{

			iframe{
				width:100%;
				height:100%;
			}

			img{
				display: block;
				max-width:100%;
				margin:auto;
			}

			@media (max-width: $screen-sm) {
				height:250px;
			}

			&--img{
				@media (max-width: $screen-sm) {
					height:auto;

					img{
						margin: 0 auto 20px auto;
					}
				}
				@media (max-width: $screen-xs) {
					img{
						margin: 0 auto;
					}
				}
			}
		}
	}

	&__next{
		display: inline-block;
		padding: 10px 30px;
		border-radius:20px;
		color:white;
		background: $darkgrey;
		text-decoration:none;
		font-size:18px;

		@media (max-width: $screen-lg) {
			font-size:16px;
		}
		@media (max-width: $screen-sm) {
			font-size:14px;
		}
		@media (max-width: $screen-xs) {
			display: block;
			margin:auto;
			text-align:center;
			width:254px;
		}
	}

}