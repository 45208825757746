.section_landing {
	.banner {
		display: flex;
		flex-direction: column;

		@media (min-width: $screen-md) {
			flex-direction: row;
		}

		.banner_text {
			padding: 43px 0 0px;
			width: 100%;
			
			@media (min-width: $screen-md) {
				max-width: 61%;
				padding: 43px 0 48px;
			}
			p {
				color: $darkred;
				font-family: 'Roboto-Regular';
				font-size: 26px;
				line-height: 32px;
				
			}

			a{
				&.arrow{
					margin-top:30px;
					text-decoration:none;
					padding-left:40px;
					height: auto;
					line-height: inherit;
					background: url(../images/arrow.png) no-repeat left center / auto 20px;
					display: block;
					font-size: 18px;

					@media (min-width: $screen-sm) {
						padding-left:55px;
						font-size: 26px;
						background: url(../images/arrow.png) no-repeat left center;
					}
				}
			}


		}

		.social {
		    align-items: center;
		    color: $darkgrey;
		    display: flex;
		    font-family: 'Roboto-Medium';
		    font-size: 20px;
		    
		    line-height: 18px;
		    margin-top: auto;
		    padding: 18px 0 38px;

		    @media (min-width: $screen-md) {
		    	margin-left: auto;
		    	justify-content: center;
		    	padding: 50px 0;
		    }

		    a {
		    	&:first-child {
		    		padding: 0 27px;
		    	}
		    }
		}
	}

	.nav-bar {
		display: flex;
		flex-direction: column;
		background: #fff;
		padding-top: 40px;
		padding-bottom: 27px;
		@media (min-width: $screen-xs) {
			flex-direction: row;
		}

		@media (min-width: $screen-md) {
			
			padding-top: 22px;
			padding-bottom: 24px;
		}
		
		h3 {
			color: $lightred;
			font-family: 'Roboto-Medium';
			font-size: 24px;
			line-height: 30px;
			padding-bottom: 25px;

			@media (min-width: 570px) {
				padding-bottom: 0px;
				min-width: 168px;	
			}
		}

		ul {
			display: block;
			@media (min-width: $screen-xs) {
				padding-left: 42px;
			}
			@media (min-width: 570px) {
				align-items: center;
				display: flex;
				padding: 0;
				flex-wrap: wrap;
			}

			li {
				color: $lightergrey;
				font-family: 'Roboto-Regular';
				font-size: 20px;
				padding-bottom: 12px;

				@media (min-width: 570px) {
					padding-left: 42px;
					width: 50%;
					padding-bottom: 0;
				}
				@media (min-width: 798px) {
					width: 33%;
				}
				@media (min-width: 962px) {
					width: auto;
				}
			}
		}
	}

	.join-form {
		background: $lightred;

		.join_form_inner {
			padding-top: 67px;
			padding-bottom: 20px;
			@media (min-width: $screen-md) {
				padding-bottom: 32px;
			}

			.form_main {
				display: flex;
				flex-direction: column;

				@media (min-width: $screen-md) {
					flex-direction: row;
				}

				.join-text {
					@media (min-width: $screen-md) {
						width: 245px;
						margin-right: 59px;
					}

					h3 {
						padding-bottom: 17px;
						font-family: 'Roboto-Regular';
						
						@media (min-width: $screen-md) {
							font-family: 'Roboto-Medium';
						}
					}
					p {
						padding-bottom: 14px;

						@media (min-width: $screen-md) {
							padding-bottom: 0;
						}
					}
				}

				.frm_forms {
					padding-top: 6px;

					@media (min-width: $screen-md) {
						width: 500px;
					}

					.frm_message {
						margin-bottom: 17px;
					}

					.frm_error {
						color: #fff;
						font-family: 'Roboto-Bold';
						font-size: 18px;
						line-height: 24px;
						margin-bottom: 17px;
					}

					fieldset {
						border: 0;
						margin: 0;
						padding: 0;

						input {
							background: #fff;
							border: 0;
							color:  $darkgrey;
							font-family: 'Roboto-Regular';
							font-size: 18px;
							line-height: 24px;
							margin-bottom: 25px;
							padding: 0 30px;
							width: 100%;
							height:40px;
							line-height:40px;
							

							@media (min-width: $screen-md) {
								padding: 12px 10px;
								margin-bottom: 17px;
							}

						}
						
						input[type="submit"] {
							background: $darkgrey;
							color: #fff;
							margin-bottom: 7px;
							margin-top: 1px;
							padding-left: 50px;
							padding-right: 62px;
							width: auto;
							border-radius:40px;

							@media (min-width: $screen-md) {
								margin-top: 3px;
								padding-left: 35px;
								padding-right: 40px;
							}
						}

					}
				}
			}
			.join_quote {
				p {
					font-size: 30px;
					color: $darkgrey;
					padding-top: 47px;
					text-align: right;

					@media (min-width: $screen-md) {
						padding-top: 0;
						
					}
				}
			}
		}
	}

	&--page{
		padding-top: 20px;

		@media (min-width: $screen-sm) {
			padding-top: 90px;
		}

		.banner{
			padding-left: 50px;
			margin-bottom:50px;
			font-family: 'Roboto-Regular';

			@media (min-width: $screen-sm) {
				padding-left: 350px;
			}

			/* Override bootstrap ul ol styling */
			ul {
				margin: 0 0 20px 20px;

				li {
					list-style: disc outside none;
				}
			}


			ol {
				margin: 0 0 20px 20px;

				li{
					list-style: decimal outside;
				}

			}


			ul{
				li{
					font-size: 18px;
					line-height: 24px;
				}
			}


			h2{
				font-family: 'Roboto-Regular';
				margin-bottom:20px;
				margin-top:20px;
				display: inline-block;
			}

			p{
				color:black;
				margin-bottom:20px;
			}
			ul{
				margin-bottom:20px;
			}

			blockquote{
				color: #de432d;
				line-height:30px;
				font-size: 22px;
				padding: 0;
				margin: 0 0 20px 0;

				p{
					color: #de432d;
					line-height:30px;
					font-size: 22px;
				}
			}

			a{
				color: #de432d;
				text-decoration:none;

				&.download_btn{
					color: #de432d;
					text-decoration: none;
					padding-left: 40px;
					background: url(../images/file_icon.png) no-repeat left center;
					height: 30px;
					line-height: 30px;
					display: inline-block;
					margin-bottom: 5px;
				}
			}
		}

	}
}