.page_default{
	.row{
		display: block;
		background: white;
		padding: 50px;
		color: #2f2f2f;

		@media (max-width: $screen-sm) {
			padding: 30px;
		}
	}

	form{

		@media (max-width: $screen-xs) {
			font-size:13px;
		}

		label{
			margin-bottom:3px;
			display: block;
		}
		.frm_message {
			margin-bottom: 17px;
		}

		.frm_required{
			color:#db432c;
		}

		.frm_error {
			color: $darkred;
			font-family: 'Roboto-Bold';
			font-size: 14px;
			margin-bottom: 14px;
		}

		fieldset {
			border: 0;
			margin: 0;
			padding: 0;

		}

		input[type=text],
		input[type=email]{
			background: #fff;
			border: 0;
			color:  $darkgrey;
			font-family: 'Roboto-Regular';
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 25px;
			padding: 0 30px;
			width: 100%;
			height:40px;
			line-height:40px;
			border: 1px solid #b3b3b3;

			@media (min-width: $screen-md) {
				margin-bottom: 17px;
			}

		}

		input[type="submit"] {
			background: $darkgrey;
			color: #fff;
			margin-bottom: 7px;
			margin-top: 1px;
			width: auto;
			border-radius:40px;
			height:40px;
			line-height:40px;
			cursor:pointer;
			border:0;
			margin-bottom:40px;
			width:110px;
			display: block;

			@media (min-width: $screen-md) {
				margin-top: 3px;
			}
			@media (max-width: $screen-xs) {
				margin: 0 auto 40px auto;
			}
		}

		.frm_radio{
			margin-bottom: 17px;
		}

		div.radio {
			margin-right:10px;
			
			span{
				border: 1px solid #b3b3b3;

				&.checked{
					background: #b3b3b3;
				}
			}	
		}


	}

	&--contact{
		.row{
			padding-top:0;
		}
	}
}