.footer {
	.row {
	    background: #fff;
	    display: flex;
	        justify-content: flex-start;
	    align-items: center;
	    padding-top: 30px;
	    padding-bottom: 30px;
	    flex-direction:column;

	    @media (min-width: $screen-sm) {
	    	flex-direction:row;
	    }

	    img {
	    	width: 70px;
	    	height: auto;
	    	margin-right: 20px;
	    }

	    span {
	    	font-size: 16px;
	    	line-height: 18px;
	    	font-family: 'Roboto-Regular';
	    }

	    p{
	    	color:black;
	    	font-size:12px;
	    	font-family: 'Roboto-Regular';
	    	text-align:center;

		    @media (min-width: $screen-sm) {
		    	text-align:left;
		    }
	    }
	}
}