// uniform helper

div.selector,
div.selector span,
div.checker span,
div.radio span,
div.uploader,
div.uploader span.action,
div.button,
div.button span {
	-webkit-font-smoothing: antialiased;
}

div.selector,
div.checker,
div.button,
div.radio,
div.uploader {
	display: -moz-inline-box;
	display: inline-block;
	*display: inline;
	zoom: 1;
	vertical-align: middle;
}

div.selector:focus,
div.checker:focus,
div.button:focus,
div.radio:focus,
div.uploader:focus {
	outline: 0;
}

div.selector,
div.selector *,
div.radio,
div.radio *,
div.checker,
div.checker *,
div.uploader,
div.uploader *,
div.button,
div.button * {
	margin: 0;
	padding: 0;
}

/* Input & Textarea */

input.uniform-input,
select.uniform-multiselect,
textarea.uniform {
	padding: 3px;
	outline: 0;
}

div.checker input,
input[type="search"],
input[type="search"]:active {
	-moz-appearance: none;
	-webkit-appearance: none;
}

/* Select */

div.selector {
	height: 26px;
	line-height: 26px;
	overflow: hidden;
	padding: 0 0 0 10px;
	position: relative;
}

div.selector span {
	cursor: pointer;
	display: block;
	display: block;
	height: 26px;
	line-height: 26px;
	overflow: hidden;
	padding-right: 25px;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

div.selector.fixedWidth {
	width: 190px;
}

div.selector.fixedWidth span {
	width: 155px;
}

div.selector select {
	-moz-opacity: 0;
	background: none;
	border: none;
	filter: alpha(opacity=0);
	height: 22px;
	left: 0px;
	opacity: 0;
	position: absolute;
	top: 2px;
	width: 100%;
}
  

/* Checkbox */

div.checker {
	position: relative;
}

div.checker,
div.checker span,
div.checker input {
	width: 19px;
	height: 19px;
}

div.checker span {
	display: -moz-inline-box;
	display: inline-block;
	*display: inline;
	zoom: 1;
	text-align: center;
}

div.checker input {
	opacity: 0;
	filter: alpha(opacity=0);
	-moz-opacity: 0;
	border: none;
	background: none;
	display: -moz-inline-box;
	display: inline-block;
	*display: inline;
	zoom: 1;
}

/* Radio */

div.radio {
	position: relative;
}

div.radio,
div.radio span,
div.radio input {
	height: 18px;
	width: 18px;
}

div.radio span {
	*display: inline;
	display: -moz-inline-box;
	display: inline-block;
	text-align: center;
	zoom: 1;
}

div.radio input {
	*display: inline;
	-moz-opacity: 0;
	background: none;
	border: none;
	display: -moz-inline-box;
	display: inline-block;
	filter: alpha(opacity=0);
	opacity: 0;
	text-align: center;
	zoom: 1;
}


/* Uploader */

div.uploader {
	height: 28px;
	width: 190px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

div.uploader span.action {
	height: 28px;
	line-height: 28px;
	width: 85px;
	text-align: center;
	float: left;
	display: inline;
	overflow: hidden;
	cursor: pointer;
}

div.uploader span.filename {
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	float: left;
	cursor: default;
	height: 24px;
	margin: 2px 0 2px 2px;
	line-height: 24px;
	width: 82px;
	padding: 0 10px;
}

div.uploader input {
	opacity: 0;
	filter: alpha(opacity=0);
	-moz-opacity: 0;
	border: none;
	background: none;
	position: absolute;
	top: 0;
	right: 0;
	float: right;
	cursor: default;
	width: 100%;
	height: 100%;
}

/* Buttons */

div.button {
	height: 30px;
	cursor: pointer;
	position: relative;
}

div.button a, div.button button, div.button input {
	opacity: 0.01;
	filter: alpha(opacity=1);
	-moz-opacity: 0.01;
	display: block;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
}

div.button span {
	display: -moz-inline-box;
	display: inline-block;
	*display: inline;
	zoom: 1;
	line-height: 30px;
	text-align: center;
	height: 30px;
	margin-left: 13px;
	padding: 0;
}

div.button.disabled span,
div.button.disabled.active span {
	cursor: default;
}
