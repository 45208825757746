/* base */

html {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*, *:before, *:after {
	-moz-box-sizing: inherit;
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

body {
	font-family: $sans_light;
	background: #f3f3f3;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	-webkit-text-stroke: 1px rgba(0,0,0,0);
	color:$darkgrey;
}

img {
	border: 0;

	&.alignleft{
		float:left;
	}
	&.alignright{
		float:right;
	}
}

h1, h2, h3, h4, h5, h6, p, form, input, ul, ol, li, header, nav, footer, section {
	list-style: none;
	margin: 0;
	padding: 0;
}

h2{
	font-size: 30px;
	font-family: $sans_medium;
	color: #fff;

	@media (max-width: $screen-lg) {
		font-size:25px;
	}
}

h3 {
	font-size: 24px;
	font-family: $sans_medium;
	color: #fff;

	@media (max-width: $screen-lg) {
		font-size:20px;
	}
}

header, nav, footer, section {
	display: block;
}

input:focus,
textarea:focus {
	outline: none;
}

input,
textarea,
select,
option {
	color: #333;
	font-family: $sans_light;
	font-size: 14px;
	font-weight: 300;
}

textarea {
	resize: none;
	overflow: auto;
}

table,
table td {
	border-collapse: collapse;
	border-spacing: 0;
	margin: 0;
	padding: 0;
}

a {
	color: #333;
}

p {
	font-size: 16px;
	line-height: 22px;
	font-family: $sans_light;
	color: #fff;

	@media (max-width: $screen-lg) {
		font-size:16px;
		line-height: 20px
	}
	@media (max-width: $screen-md) {

	}
						
}


.clearfix::after {
    content: "";
    clear: both;
    display: table;
}


hr{
	display: block;
	clear:both;
}