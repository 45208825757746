.experiences{
	text-align:center;

	.row{
		background: #f2e9dc;
		padding: 80px 50px;
		position: relative;

		@media (max-width: $screen-sm) {
			padding: 50px 30px 20px 30px;
		}
	}

	&__content{
		width:750px;
		max-width:100%;
		display: block;
		margin:auto;
		text-align:center;
		color:$darkgrey;

		h2{
			margin-bottom:10px;
			color:$darkgrey;
		}
		p{
			margin-bottom:40px;
			color:$darkgrey;
		}

		@media (max-width: $screen-xs) {
			text-align:left;
		}
	}

	&__row{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		> a,
		> div{
			width:50%;
			display: block;

			&:hover{
				background: white;

				img{
					opacity:0.5;
				}
			}
		}

		@media (max-width: $screen-lg) {
			flex-direction: column;

			> a,
			> div{
				width:100%;
				display: block;
			}
		}
	}

	&__item{
		text-decoration:none;
		text-align:left;

		img{
			width:320px;
			max-width:60%;
			height:auto;
			float:left;
			margin-right:20px;

			@media (max-width: $screen-sm) {
				width:auto;
				max-width:100%;
				float:none;
				display: block;
				margin:auto;
			}
		}
		p{
			padding: 30px 0 0 0;
			color:$darkred;
			font-size:20px;
			font-family: $sans_medium;

			@media (max-width: $screen-lg) {
				font-size:16px;
			}
			@media (max-width: $screen-sm) {
				padding: 30px 0;
			}
		}

		&:first-of-type{
			border-right:1px solid #c6bfb5;
			padding-right:30px;

			@media (max-width: $screen-lg) {
				padding: 0;
				margin-bottom:30px;
				border:0;
			}
			@media (max-width: $screen-sm) {
				padding-right:0;
				border-bottom:1px solid #c6bfb5;
				margin-bottom:30px;
			}
		}
		&:last-of-type{
			padding-left:50px;

			@media (max-width: $screen-lg) {
				padding: 0;
			}
		}
	}
}
