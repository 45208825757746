.fromtheblog{
	.row{
		background: #f2e8dc;
		padding: 50px 0;
		color:$darkred;
		display: flex;
        flex-direction: column;
        align-items: center;
          justify-content: center;
          text-align:center;

		@media (max-width: $screen-sm) {
			padding: 50px 30px;
		}
		@media (max-width: $screen-xs) {
			 align-items: left;
			 text-align:left;
		}

		h2{
			color:$darkgrey;
			margin-bottom:20px;
		}
		.img{
			max-width:100%;
			height:auto;
			margin-bottom:10px;

			img{
				max-width:100%;
				height:auto;
				display: block;
			}
		}

		a{
			text-decoration:none;
		}
		p{
			color:$darkred;
		}

		a{
			@media (min-width: $screen-sm) {
				background: white;
				width:750px;
				max-width:100%;

				.img{
					float:left;
					margin:0;
					width:50%;

					img{
						width:100%;
					}
				}
				p{
					float:left;
					padding: 50px 30px;
					font-family: "faricy-new-web", sans-serif;
					font-size: 22px;
					font-weight: 500;
					line-height: 1.3;
					    width: 50%;
    text-align: left;
				}
			}
		}
	
	}
}