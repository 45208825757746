.tenthings{
	
	.row{
		background: url(../images/tenthings/tenthings_bg.jpg) no-repeat center center / cover;
		padding: 80px 0;
		position: relative;
		display: block;
		text-decoration:none;

		@media (max-width: $screen-md) {
			padding: 80px 100px;
		}
		@media (max-width: $screen-sm) {
			padding: 50px 30px 100px 30px;
		}
	}

	&__content{
		display: block;
		max-width:100%;
		width:750px;
		margin:auto;
		text-align:center;
		color:$darkgrey;

		h2{
			margin-bottom:10px;
			color:$darkgrey;
		}
		p{
			margin-bottom:40px;
			color:$darkgrey;
		}

		img{
			max-width:100%;
			display: block;
			margin:auto;
		}

		@media (max-width: $screen-xs) {
			text-align:left;
		}


	}

	&__next{
		display: inline-block;
		position: absolute;
		bottom:50px;
		right:50px;

		img{
			display: block;
			width:140px;
			height:140px;
		}

		@media (max-width: $screen-md) {
			bottom:0;
			right:0;

			img{
				width:100px;
				height:100px;
			}
		}
	}
}


