/* generic */


.clearboth {
	clear: both;
}

.fl {
	float: left;
}

.fr {
	float: right;
}

.videowrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videowrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

textarea,
button,
input,
input[type="text"],
input[type="button"],
input[type="submit"] {
     -webkit-appearance: none;
     border-radius: 0;
     outline: none;
}

	// js responsive states

	.state-indicator {
		position: absolute;
		top: -999em;
		left: -999em;
		z-index: 1;
	}

	/* tablet */
	@media all and (max-width: 992px) {
		
		.state-indicator {
			z-index: 7;
		}
	}