//media query variables
$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;
$screen-lgr:                 1440px !default;

//colors
$darkred: 		#d73e28;
$lightred: 		#e84934;

$darkgrey: 		#2f2f2f;
$lightergrey:	#3e3e3e;

//fonts
$sans_light: "faricy-new-web",sans-serif;
$sans_medium: "faricy-new-web",sans-serif;
//$sans_light: 'Roboto-Light', sans-serif;
//$sans_medium: 'Roboto-Medium', sans-serif;