/* custom cookie styles */


.has-cookie-bar #catapult-cookie-bar{
  font-family: 'Roboto-Regular';
    font-size: 15px;
        color: white;
}

#catapult-cookie-bar a{
    color: #5b8edc !important;
    text-decoration: none;
}

.cookie-bar-bar button#catapultCookie{
    color: white;
    background: transparent url(../images/x.png) no-repeat right center;
    border: 0;
    padding-right:30px;
}

.cookie-bar-bar .ctcc-inner{
  justify-content: left !important;
}

.ctcc-right-side{
    position: absolute;
    right: 0;  
}


@media (max-width: 650px) {

  .has-cookie-bar #catapult-cookie-bar{
    font-size:12px;
  }

  .ctcc-right-side{
    position: relative;
    right:inherit;
  }

  .cookie-bar-bar .ctcc-inner{
    justify-content: center !important;
    flex-direction: column;
  }

  .ctcc-left-side {
      text-align: center;
      margin-bottom:10px;
  }

  .cookie-bar-bar button#catapultCookie{
    font-size:12px;
  }

}